import { handleLoginResponse, requestHeader } from "~/helpers/fetch-helpers";
import { cacheEvent, cacheTokens, getEventId, removeCachedEvent } from "~/helpers/token-helpers";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const fetchEvent = async( eventId: string | undefined, eventAccessCode: string ) => {	
	try {
		if (!eventId) {
			throw new Error('Event id was not provided');
		}

		const resp = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/liveEvent/publicEventInfo/${eventId}`);
		const data = await resp.json();

		if (!data?.eventId) { throw new Error('Event not found'); }

		cacheEvent(eventAccessCode, data);

		return {
			ok: true,
			...data
		}
	
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return {
			ok: false,
			errorMessage: error.errorMessage
		}
	}
}


export const fetchTokens = async ( eventAccessCode: string ) => {
	const fp = await FingerprintJS.load({
		monitoring: false,
	});
	const result = await fp.get();

	try {
		const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/connect/token`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Device-Id': result.visitorId,
			},
			body: new URLSearchParams({
				code: eventAccessCode,
				grant_type: 'otp',
				scope: 'openid offline_access',
				login_hint: 'scanner',
			}),
		});

		if (!response.ok) { return null }
		const tokens = await handleLoginResponse(response);
		if (eventAccessCode) { cacheTokens(eventAccessCode, tokens); }

		return {
			ok: true,
			tokens
		}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return {
			ok: false,
			errorMessage: error.message
		}
	}
};

export const getScannerTicket = async (result: string, eventAccessCode: string, currentDate: string, isMultipleScannedInEnabled = false, retryCount = 0) => {
	const fp = await FingerprintJS.load({
		monitoring: false,
	});
	const fpResult = await fp.get();

	const requestOptions = {
		method: 'GET',
		headers: { ...requestHeader(eventAccessCode), 'Device-Id': fpResult.visitorId },
	};

	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/scanner/ticket/${result}/${currentDate}`,
			requestOptions,
		);

		const resp = await response;
		if ( !resp.ok ) {
			const text = await resp.text();
			const { message } = JSON.parse(text);
			throw new Error(message ?? 'Something was wrong with user ticket');
		} else {
			const data = await response.json();

			if (response.status === 401) {
				refetchWithNewCredentials( eventAccessCode, response.status, null, isMultipleScannedInEnabled, retryCount);
				return;
			}

			if (response.status === 200) {
				const isAdmittedBefore = data.admittedOn && data.admittedOn.some((dt: string) => new Date(dt).toLocaleDateString() === new Date(currentDate).toLocaleDateString());
				return {
					ok: true,
					statusCode: 200,
					participant: { ...data, status: data.error ? 'error' : isAdmittedBefore ? 'duplicate' : 'admitted' },
					status: data.error ? 'error' : isAdmittedBefore ? 'duplicate' : 'admitted',
				}
			}

			if (response.status === 403) {
				refetchWithNewCredentials(eventAccessCode, response.status, data.message, isMultipleScannedInEnabled, retryCount);
				return;
			}

			return {
				ok: false,
				errorMessage: data.message
			}
		}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return {
			ok: false,
			errorMessage: e.message
		}
	}

	async function refetchWithNewCredentials(
		eventAccessCode: string,
		responseCode: number,
		responseMessage: string | null,
		isMultipleScannedInEnabled: boolean,
		retryCount: number,
	) {
		if (retryCount > 3) {
			let errorMessage = 'Cannot proceed scanning. Please contact admin with error';
			if (responseCode === 401) {
				errorMessage = `${errorMessage}: ${responseCode}`;
			} else {
				errorMessage = `${errorMessage}: ${responseCode}: ${responseMessage}`;
			}
			
			return {
				ok: false,
				errorMessage: errorMessage
			}
		}
		removeCachedEvent(eventAccessCode);
		await fetchTokens( eventAccessCode );
		await fetchEvent(getEventId(eventAccessCode), eventAccessCode);
		await getScannerTicket(result, eventAccessCode, new Date().toJSON(), isMultipleScannedInEnabled);
	}
};

export const getParticipantsAdmitted = async( eventId: string, currentDate: string ) => {
	const requestOptions = {
		method: 'GET',
	};

	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/registration/${eventId}/registrationsAdmitted/${currentDate}`,
			requestOptions,
		);

		const data = await response.json();

		return {
			ok: true,
			participants: data
		}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch ( e: any ) {
		return {
			ok: false,
			errorMessage: e.message
		}
	}
}

export const consumeVoucherToParticipant = async( eventAccessCode: string, eventId: string, participantId: string ) => {
	const fp = await FingerprintJS.load({
		monitoring: false,
	});
	const fpResult = await fp.get();

	const params = JSON.stringify({ eventId: eventId, ticketId: participantId });

	const requestOptions = {
		method: 'POST',
		headers: { ...requestHeader(eventAccessCode), 'Device-Id': fpResult.visitorId },
		body: params
	};

	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/scanner/voucher/consume`,
			requestOptions,
		);

		const data = await response.json();

		if ( !response.ok ) {
			return {
				ok: false,
				errorMessage: data.errorMessage
			}
		}

		return {
			ok: true,
			data
		}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch ( e: any ) {
		return {
			ok: false,
			errorMessage: e.message
		}
	}
}

export const saveScannerName = async( eventAccessCode: string, eventId: string, scannerName: string ) => {

	const params = JSON.stringify({ eventId: eventId, eventAccessCode: eventAccessCode, scannerName: scannerName });

	const requestOptions = {
		method: 'POST',
		headers: { ...requestHeader(eventAccessCode)},
		body: params
	};

	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/scanner/addScannerName`,
			requestOptions,
		);

		const data = await response.json();

		if ( !response.ok ) {
			return {
				ok: false,
				errorMessage: data.errorMessage
			}
		}

		return {
			ok: true,
			data
		}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch ( e: any ) {
		return {
			ok: false,
			errorMessage: e.message
		}
	}
}
