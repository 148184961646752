import jwtDecode from 'jwt-decode';
import { Event } from '~/types/Event';
import { OpenIdTokens } from '~/types/OpenIdTokens';

export function getTokens(eventAccessCode: string) {
	const tokensKey = `tokens_${eventAccessCode}`;
	const tokens = localStorage.getItem(tokensKey);
	if (tokens) {
		return JSON.parse(tokens);
	}

	return undefined;
}

export function cacheTokens(eventAccessCode: string, tokens: OpenIdTokens) {
	const tokensKey = `tokens_${eventAccessCode}`;
	localStorage.setItem(tokensKey, JSON.stringify(tokens));
}

export function cacheEvent(eventAccessCode: string, event: Event) {
	const eventsKey = `events_${eventAccessCode}`;
	localStorage.setItem(eventsKey, JSON.stringify(event));
}

export function getIdToken(eventAccessCode: string) {
	const tokensKey = `tokens_${eventAccessCode}`;
	if (localStorage.getItem(tokensKey)) {
		return JSON.parse(localStorage.getItem(tokensKey) || '{}')?.id_token;
	}

	return null;
}

export function getCachedEvent(eventAccessCode: string) {
	const eventsKey = `events_${eventAccessCode}`;
	if (localStorage.getItem(eventsKey)) {
		return JSON.parse(localStorage.getItem(eventsKey) || '{}');
	}

	return null;
}

export function getCachedEvents(): [key: string, value: Event][] {
	return Object.entries(localStorage)
		.filter(([key]) => key.startsWith('events_'))
		.map(([key, value]) => [key.replace('events_', ''), JSON.parse(value)]);
}

export function getAccessToken(eventAccessCode: string) {
	const tokensKey = `tokens_${eventAccessCode}`;
	if (localStorage.getItem(tokensKey)) {
		return JSON.parse(localStorage.getItem(tokensKey) || '{}')?.access_token;
	}

	return null;
}

export function getDecodedToken(eventAccessCode: string) {
	const idToken = getIdToken(eventAccessCode);
	return idToken && jwtDecode(idToken);
}

export function getEventId(eventAccessCode: string): string | undefined {
	const token = getDecodedToken(eventAccessCode);
	if (token) {
		return token.eventId;
	}

	return undefined;
}

export function removeCachedEvent(eventAccessCode: string): void {
	localStorage.removeItem(`events_${eventAccessCode}`);
	localStorage.removeItem(`tokens_${eventAccessCode}`);
}

export function setScannerUserToken(eventAccessCode: string, scannerName: string) {
	const scannerUserKey = `scannerUser_${eventAccessCode}`;
	localStorage.setItem(scannerUserKey, scannerName );
}

export function getScannerUserToken(eventAccessCode: string) {
	const tokensKey = `scannerUser_${eventAccessCode}`;
	if (localStorage.getItem(tokensKey)) {
		return localStorage.getItem(tokensKey);
	}
	return null;
}