import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { EventState } from '~/store';
import { clearLoadedParticipant, setMessageError } from "../../store/participant/participantSlice";
import { startLoadingParticipants, startScanningVoucher } from '~/store/participant/thunk';
import { useAppDispatch, useAppSelector } from '~/hooks/hooks';
import { Header } from '../Header/Header';
import QrReader from 'react-qr-reader';
import { Button, Loader, Message } from 'rsuite';
import Swal from 'sweetalert2';
import { ModalUserMessage } from '~/components/ModalUserMessage/ModalUserMessage';

import './style.css';
import styles from './VoucherScanner.module.less';
import { useNavigate } from 'react-router-dom';

export const VoucherScanner = () => {

	const { id: eventId, errorMessage } = useAppSelector( (state: EventState) => state.event );
	const { isScanningVouchers, messageError: scannerError, lastLoaded: user, messageSaved: scannerSuccess  } = useAppSelector( (state: EventState) => state.participant );
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const currentDate = new Date().toJSON();
		// Load participants admitted
		dispatch( startLoadingParticipants( currentDate ) );
	}, []);

	useEffect(() => {
		if (scannerError && scannerError.length > 0) {
			const html = ReactDOMServer.renderToStaticMarkup(
				<ModalUserMessage user={ user } message={ scannerError } type="error" />
			);
			Swal.fire({
				title: 'Ups!',
				width: 400,
				color: '#fff',
				html: html,
				icon: 'error',
				confirmButtonText: 'Ok',
				customClass: {
					title: 'alert-title'
				}
			}).then( () => {
				dispatch( clearLoadedParticipant() );
			});
		}
	}, [scannerError])
		
	useEffect(() => {
		if (scannerSuccess.length > 0) {
			const html = ReactDOMServer.renderToStaticMarkup(
				<ModalUserMessage user={ user } message={ scannerSuccess } type="success" />
			);
			Swal.fire({
				title: 'Good!',
				width: 400,
				color: '#fff',
				html: html,
				icon: 'success',
				confirmButtonText: 'Ok',
				customClass: {
					title: 'alert-title'
				}
			}).then( () => {
				dispatch( clearLoadedParticipant() );
			});
		}
	}, [scannerSuccess])
	
	const getResult = ( id: string ) => {
		dispatch( startScanningVoucher( id ) );
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleError = (error: any) => {
		dispatch( setMessageError( error.Message ) );
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	if (isScanningVouchers) {
		return <Loader backdrop center size="lg" />;
	}

	return (
		<>
			<Header />

			<div className={styles.container}>
				<h3 className={styles.info}>Scan vouchers</h3>
				{eventId && !errorMessage ? (
					<>
						<div className={styles['qr-content']}>
							<div
								className={styles['qr-frame']}
								style={{ display: !isScanningVouchers ? 'block' : 'none' }}
							>
								<QrReader 
										delay={2000}
										onError={(error) => {
											handleError( error );
										}}
										onScan={(data: string | null) => {
											if ( data && !isScanningVouchers ) { getResult( data ) }
										}}
										style={{ width: "100%" }}
										facingMode={'environment'}
									/>
							</div>
						</div>
						<Button onClick={ () => handleGoBack() }>Back</Button>
					</>
				) : (
					<>
						<Message type="error">{errorMessage ? errorMessage : 'Event was not found'}</Message>
					</>
				)}
			</div>
		</>
	)
}
