import { Message } from 'rsuite';
import { UserPanel } from '../UserPanel/UserPanel';
import { User } from '../../types/User';
import { TypeAttributes } from 'rsuite/esm/@types/common';

import './style.css';

type ModalUserMessageProps = {
	user: User | null,
	message: string,
	type:  TypeAttributes.Status
}

export const ModalUserMessage = ({ user, message, type="error" }: ModalUserMessageProps) => {
	return (
		<div>
			<Message showIcon type={ type }>
				{ message === 'duplicate' && 'Participant has already been admitted previously' }
				{ message === 'admitted' && 'Participant admitted successfully' }
				{ message !== 'duplicate' && message !== 'admited' && message }
			</Message>
			{ user != null && <UserPanel user={ user } /> }
		</div>
	)
}
