import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventState } from '~/store';
import { logout } from '~/store/event';
import { clearParticipantsLogout } from '~/store/participant/participantSlice';
import { useAppSelector, useAppDispatch } from '../../hooks/hooks';
import QrReader from 'react-qr-reader';
import { Button, Input, Loader, Message } from 'rsuite';
import { EventList } from '~/containers/EventList/EventList';
import { getCachedEvents, removeCachedEvent } from '~/helpers/token-helpers';
import logo from '../../tappin-logo.svg';

import styles from './InitializeEvent.module.less';

export const InitializeEvent = () => {
	
	const [code, setCode] = useState('');
	const [error, setError] = useState<string | undefined>(undefined);
	const [availableEvents, setAvailableEvents] = useState(getCachedEvents());
	const dispatch = useAppDispatch();
	const { status, accessCode, errorMessage } = useAppSelector( (state: EventState) => state.event );
	const isLoading = useMemo(() => status === 'checking', [status]);
	const navigate = useNavigate();

	useEffect(() => {
		if ( status === 'loaded' ) {
			dispatch( logout('') );
			dispatch( clearParticipantsLogout() );
		}
	}, []);

	useEffect(() => {
		if (errorMessage && errorMessage.length > 1) {
			setError(errorMessage);
		} else {
			setError('');
		}
	}, [errorMessage]);

	useEffect(() => {
		if ( status === 'loaded' ) {
			navigate(`event/${accessCode}`);
		}
	}, [accessCode]);

	function getResult(text: string) {
		if (text.startsWith(window.location.origin)) {
			const code = text.slice(text.lastIndexOf('/') + 1);
			navigate(`event/${code}`);
			return;
		}

		setError(`Could not find event from provided QR code "${text}"`);
	}

	function handleCodeChange(value: string) {
		setCode(value);
	}

	function handleGoToEvent() {
		if (code.trim().length == 0) return;
		navigate(`event/${code}`);
		return;
	}

	function handleEventRemove(accessKey: string) {
		removeCachedEvent(accessKey);
		setAvailableEvents(getCachedEvents());
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleError = (error: any) => {
		setError(error.Message);
	};

	if (status === 'checking') {
		return <Loader backdrop center size="lg" />;
	}
	
	return (
		<div className={styles.container}>
			<img src={logo} className={styles.logo} alt="logo" />
			<div className={styles.content}>
				<h3 className={styles.info}>Scan event QR code</h3>
				<div className={styles['qr-container']}>
					<div className={styles['qr-content']}>
						<div className={styles['qr-frame']}></div>
						<QrReader 
							delay={300}
							onError={(error) => {
								handleError( error );
							}}
							onScan={(data: string | null) => {
								if (data) { getResult( data ) }
							}}
							style={{ width: "100%" }}
							facingMode={'environment'}
						/>
					</div>
					<div>{error && <Message type="error">{error}</Message>}</div>
				</div>
				<h4 className={styles.hr}>
					<span>OR</span>
				</h4>

				<h3 className={styles.info}>Enter event code</h3>

				<div className={styles['code-container']}>
					<Input
						className={styles['code-input']}
						onChange={handleCodeChange}
						value={code}
					/>
					<Button 
						onClick={handleGoToEvent} 
						disabled={ isLoading }
					>
						Go to event
					</Button>
				</div>
				{availableEvents.length > 0 && (
					<>
						<h4 className={styles.hr}>
							<span>OR</span>
						</h4>
						<h3 className={styles.info}>Choose previous event</h3>
						<EventList
							theme="dark"
							events={availableEvents}
							onEventRemove={handleEventRemove}
						/>
					</>
				)}
			</div>
		</div>
	);
};
