import { createSlice } from '@reduxjs/toolkit';
import { EventState } from '~/types/EventState';

const initialState: EventState = {
	status: 'not-loaded',
	id: null,
	name: null,
	logoURL: null,
	accessCode: null,
	slug: null,
	isMultipleScannedInEnabled: null,
	errorMessage: null,
	isAdding: false,
}

export const eventSlice = createSlice({
	name: 'event',
	initialState,
	reducers: {
		getEvent: ( state, action ) => {
			state.status = 'loaded';
			state.id = action.payload.eventId;
			state.name = action.payload.eventName;
			state.logoURL = action.payload.logoUrl;
			state.accessCode = action.payload.eventAccessCode;
			state.slug = action.payload.eventSlug;
			state.isMultipleScannedInEnabled = action.payload.isMultipleScannedInEnabled;
		},
		logout: ( state, action ) => {
			state.status = 'not-loaded';
			state.id = null;
			state.name = null;
			state.logoURL = null;
			state.accessCode = null;
			state.slug = null;
			state.isMultipleScannedInEnabled = null;
			state.errorMessage = action.payload ?? null;
		},
		checkingEventData: ( state ) => {
			state.status = 'checking';
			state.id = null;
			state.name = null;
			state.logoURL = null;
			state.accessCode = null;
			state.slug = null;
			state.isMultipleScannedInEnabled = null;
		},
		addingScannerName: ( state ) => {
			state.isAdding = true;
		},
		setLoadedScannedName: ( state, action ) => {
			state.isAdding = action.payload;
		},
		setMessageError: (state, action) => {
			state.errorMessage = action.payload
		},
	}
});

// Action creators are generated for each case reducer function
export const { getEvent, logout, checkingEventData, addingScannerName, setLoadedScannedName, setMessageError } = eventSlice.actions;
