import { fetchEvent, fetchTokens, saveScannerName } from "~/api/provider";
import { getEventId, getTokens, setScannerUserToken } from "~/helpers/token-helpers";
import { addingScannerName, checkingEventData, getEvent, logout, setLoadedScannedName, setMessageError } from ".";

export const checkingEvent = ( eventAccessCode ) => {
	return async( dispatch ) => {
		dispatch( checkingEventData() );

		let errorMessage = 'Event not found';
		const id = getEventId( eventAccessCode );
		let event = null;
		if (getTokens( eventAccessCode )) {
			const tokens = await fetchTokens( eventAccessCode );
			if (tokens.ok) {
				event = await fetchEvent(id, eventAccessCode)
			} else {
				errorMessage = tokens.errorMessage ?? errorMessage;
			}			
		} else {
			const tokens = await fetchTokens( eventAccessCode );
			if (tokens.ok) {
				event = await fetchEvent(getEventId( eventAccessCode ), eventAccessCode)
			} else {
				errorMessage = tokens.errorMessage ?? errorMessage;
			}
		}

		event = { ...event, eventAccessCode };

		if (!event.ok) {
			dispatch( logout( errorMessage ) );
		} else {
			dispatch( getEvent( event ) );
		}
	}
}

export const saveScanner = ( eventAccessCode, scannerName ) => {
	return async( dispatch) => {
		dispatch( addingScannerName() );

		const id = getEventId( eventAccessCode );
		
		const resp = await saveScannerName(eventAccessCode, id, scannerName) ;
		if(resp.ok) {
			dispatch( setLoadedScannedName( true ) );
			setScannerUserToken(eventAccessCode, scannerName)
		}
		else {

		const errorMessage = resp.errorMessage ? resp.errorMessage : resp.status;
			dispatch( setMessageError( errorMessage ) );
			dispatch( setLoadedScannedName( false ) );
		}
	}
}