import { Panel } from 'rsuite';
import { User } from '~/types/User';
import defaultUserPhoto from '~/user-solid.svg';

import styles from './UserPanel.module.less';

export const UserPanel = ({ user }: { user: User }) => (
	<>
		<Panel shaded bodyFill className={styles['user-panel']}>
			<img src={user.photo && user.photo != "" ? user.photo : defaultUserPhoto } alt="Avatar" height="100" />
			<div>{`${user.name ?? ''} ${user.lastName ?? ''}`}</div>
		</Panel>
	</>
);
