import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { EventState } from '~/store';
import { logout } from '~/store/event';
import { startAddingParticipant, startLoadingParticipants } from '~/store/participant/thunk';
import { clearLoadedParticipant, setMessageError } from '~/store/participant/participantSlice';
import { useAppDispatch, useAppSelector } from '~/hooks/hooks';
import { Message, Loader, Button } from 'rsuite';
import Swal from 'sweetalert2';
import QrReader from 'react-qr-reader';
import { Header } from '../Header/Header';
import { ModalUserMessage } from '~/components/ModalUserMessage/ModalUserMessage';

import styles from './TicketScanner.module.less';

const TicketScanner = () => {

	const { status, id: eventId, name, errorMessage } = useAppSelector( (state: EventState) => state.event );
	const { lastLoaded: user, messageSaved: scannerSuccess, messageError: scannerError, isLoadedScannedIn } = useAppSelector( (state: EventState) => state.participant);
	const [loading, setLoading] = useState<boolean>(true);
	const [enabled, setEnabled] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (status === 'loaded') {
			setLoading(false);
			window.document.title = name ? name : 'Ticket scanner';

			const currentDate = new Date().toJSON();
			dispatch( startLoadingParticipants( currentDate ) );
		} else {
			logout('Event not loaded');
			navigate('/');
		}
	}, []);

	useEffect(() => {
		setEnabled(isLoadedScannedIn)
	}, [isLoadedScannedIn])

	useEffect(() => {
		if (scannerError && scannerError.length > 0) {
			const html = ReactDOMServer.renderToStaticMarkup(
				<ModalUserMessage user={ user } message={ scannerError } type="error" />
			);
			Swal.fire({
				title: 'Ups!',
				width: 400,
				color: '#fff',
				html: html,
				icon: 'error',
				confirmButtonText: 'Ok',
				customClass: {
					title: 'alert-title'
				}
			}).then( () => {
				dispatch( clearLoadedParticipant() );
				setLoading( false );
			});
		}
	}, [scannerError]);
		
	useEffect(() => {
		if (scannerSuccess.length > 0) {
			const html = ReactDOMServer.renderToStaticMarkup(
				<ModalUserMessage user={ user } message={ scannerSuccess } type="success" />
			);
			Swal.fire({
				title: 'Good!',
				width: 400,
				color: '#fff',
				html: html,
				icon: 'success',
				confirmButtonText: 'Ok',
				customClass: {
					title: 'alert-title'
				}
			}).then( () => {
				dispatch( clearLoadedParticipant() );
				setLoading( false );
			});
		}
	}, [scannerSuccess]);

	const getResult = async (result: string) => {
		setLoading( true );
		const currentDate = new Date().toJSON();
		dispatch( startAddingParticipant( result, currentDate ) );
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleError = (error: any) => {
		dispatch( setMessageError( error.Message ) );
	};

	const handleGoBack = () => {
		setEnabled(false);
		navigate(-1);
	};

	if (loading) {
		return <Loader backdrop center size="lg" />;
	}

	return (
		<>
			<Header />

			<div className={styles.container}>
				<h3 className={styles.info}>Scan participants</h3>
				{eventId && !errorMessage ? (
					<>
						{ !enabled && <Loader center size="sm" /> }
						{ enabled && (
							<>
								<div className={styles['qr-content']}>
									<div className={styles['qr-frame']}>
										<QrReader
											delay={2000}
											onError={(error) => {
												handleError(error);
											} }
											onScan={(data: string | null) => {
												if (data && enabled) { getResult(data); }
											} }
											style={{ width: "100%" }}
											facingMode={'environment'} />
									</div>
								</div>
								<Button onClick={() => handleGoBack()}>Back</Button>
							</>
						)}
						
					</>
				) : (
					<>
						<Message type="error">{ errorMessage ?? 'Event was not found' }</Message>
					</>
				)}
			</div>
		</>
	);
};

export default TicketScanner;
