import { consumeVoucherToParticipant, getParticipantsAdmitted, getScannerTicket } from "~/api/provider";
import { addingParticipant, consumeVoucher, scanningVoucher, setLoadedParticipant, setMessageError, setNewParticipant, setParticipants, startGettingParticipants } from "./participantSlice";

export const startLoadingParticipants = ( currentDate ) => {
	return async( dispatch, getState ) => {
		const { id: eventId } = getState().event;

		if (!eventId) throw new Error('Event not found');

		dispatch( startGettingParticipants() );

		const resp = await getParticipantsAdmitted( eventId, currentDate );

		if (resp.ok && !resp.participants?.message) {
			return dispatch( setParticipants( resp.participants ) );
		}

		const errorMessage = resp.errorMessage ?? resp.participants?.message ?? 'There is an error. Try again';

		dispatch( setMessageError( errorMessage ) );
	}
} 

export const startAddingParticipant = ( ticketId, currentDate ) => {
	return async( dispatch, getState ) => {

		dispatch( addingParticipant );

		const { accessCode, id: eventId, isMultipleScannedInEnabled } = getState().event;
		const { participants } = getState().participant;

		// Searching for duplicate participants
		if (participants && participants.length > 0 && participants.find( p => p.id === ticketId )) {
			var part = participants.find( p => p.id === ticketId );
			dispatch( setLoadedParticipant( { ...part, eventId, registrationId: ticketId, status: 'duplicate' } ) );
			return dispatch( setMessageError( 'duplicate' ) );
		}
		
		const resp = await getScannerTicket( ticketId, accessCode, currentDate, isMultipleScannedInEnabled );

		if ( resp.ok ) {
			let participant = {
				...resp.participant,
				eventId,
				registrationId: ticketId
			};
	
			dispatch( setLoadedParticipant( participant ) );
			
			if (resp.status === 'admitted') {
				participant = {
					...participant,
					admittedOn: [...participant.admittedOn, currentDate]
				};
				// participant.admittedOn.push(currentDate);
				dispatch( setNewParticipant( participant ) );
				return;
			}
		}

		const errorMessage = resp.errorMessage ? resp.errorMessage : resp.status;
		dispatch( setMessageError( errorMessage ) );
	}
}

export const startScanningVoucher = ( participantId ) => {
	return async( dispatch, getState ) => {

		dispatch( scanningVoucher() );

		const { accessCode, id: eventId } = getState().event;
		const { participants } = getState().participant;

		dispatch( setLoadedParticipant( participants.find(p => p.id === participantId ) ) );

		const resp = await consumeVoucherToParticipant( accessCode, eventId, participantId );

		if (!resp.ok) { return dispatch( setMessageError( resp.errorMessage ) )}

		dispatch( consumeVoucher( resp.data ) );
	}
}
