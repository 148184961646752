import { Navigate, Route, Routes } from 'react-router-dom';
import { ChoiceOption } from '~/containers/ChoiceOption/ChoiceOption';
import { InitializeEvent } from '~/containers/InitializeEvent/InitializeEvent';
import TicketScanner from '~/containers/TicketScanner/TicketScanner';
import { VoucherScanner } from '~/containers/VoucherScanner/VoucherScanner';
import { useCheckEventLoaded } from '~/hooks/useCheckEventLoaded';

export const AppRouter = () => {

	const status = useCheckEventLoaded();

	return (
		<>
			<Routes>
				{ 
					(status === 'loaded') 
					? (
						<>
							<Route path="event/:id/vouchers" element={<VoucherScanner />} />
							<Route path="event/:id/tickets" element={<TicketScanner />} />
							<Route path="event/:id" element={<ChoiceOption />} />
							{/* <Route path="event" element={<InitializeEvent /> } /> */}
							<Route path="/" element={<InitializeEvent />} />
							<Route path="*" element={<Navigate to="/" replace={true} />} />
						</>
						)
					: (
						<>
							<Route path="event/:id" element={<ChoiceOption />} />
							<Route path="/" element={<InitializeEvent />} />
							<Route path="*" element={<Navigate to="/" replace={true} />} />
						</>
					)
				}
			</Routes>
		</>
	)
}
