import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCachedEvent } from '~/helpers/token-helpers';
import { getEvent, logout } from '~/store/event';
import { useAppDispatch, useAppSelector } from './hooks';

export const useCheckEventLoaded = () => {
	const status = useAppSelector( (state: import('~/store').EventState) => state.event.status );
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const uris = location.pathname.split('/');
		const eventCode =  uris && uris.length > 1 && uris[2];

		if ( !eventCode ) {
			const errorMessage = location.pathname !== '/' ? 'Event nof found' : '';
			dispatch( logout( errorMessage ) );
			navigate('/');

		} else {
			const cachedEvent = getCachedEvent(eventCode);
			if ( cachedEvent ) {
				const { eventId, eventName, logoUrl, eventSlug } = cachedEvent;
				dispatch( getEvent({ eventId, eventName, logoUrl, eventSlug, eventAccessCode: eventCode }) );
			} else {
				dispatch( logout( '' ) );
				navigate('/');
			}
		}
	}, []);
	
	return status;
}
