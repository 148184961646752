import { checkingEvent } from '~/store/event';
import { useAppDispatch } from '~/hooks/hooks';
import { Event } from '~/types/Event';
import { IconButton } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';

import styles from './EventList.module.less';

export function EventList({
	theme,
	events,
	onEventRemove,
}: {
	theme: 'dark' | 'light';
	events: [key: string, value: Event][];
	onEventRemove?: (accessKey: string) => void;
}) {
	
	const dispatch = useAppDispatch();

	function handleEventRemove(accessKey: string) {
		if (onEventRemove) {
			onEventRemove(accessKey);
		}
	}

	const handleGoToEvent = ( eventCode: string ) => {
		if ( eventCode.trim().length == 0 ) return;

		dispatch( checkingEvent( eventCode ) );
	}

	return (
		<div className={styles[theme]}>
			<ul className={styles.links}>
				{events.map(([accessKey, event]) => (
					<li key={accessKey}>
						<a className={styles.link} href={`/event/${accessKey}`} onClick={ () => handleGoToEvent( accessKey ) }> 
							{event.eventName}
						</a>
						{onEventRemove && (
							<div className={styles.remove_event}>
								<IconButton
									size="xs"
									icon={<CloseIcon />}
									color="red"
									appearance="primary"
									circle
									onClick={() => handleEventRemove(accessKey)}
								/>
							</div>
						)}
					</li>
				))}
			</ul>
		</div>
	);
}
