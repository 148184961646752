import { getAccessToken } from './token-helpers';

export async function handleLoginResponse(response: Response) {
	const text = await response.text();
	const data = text && JSON.parse(text);
	if (!response.ok && data?.error) {
		const error = { message: data?.error_description, errorCode: data?.error };
		return Promise.reject(error);
	}
	if (!response.ok) {
		const message = { message: data?.message || 'Email address or password is incorrect' };
		return Promise.reject(message);
	}
	return Promise.resolve(data);
}

function getAuthorizationHeader(eventCode: string) {
	const accessToken = getAccessToken(eventCode);

	if (accessToken) {
		return { Authorization: `Bearer ${accessToken}` };
	}

	return undefined;
}

export function requestHeader(eventCode: string) {
	const authorization = getAuthorizationHeader(eventCode);

	return {
		...authorization,
		'content-type': 'application/json',
	};
}
