import { configureStore } from '@reduxjs/toolkit';
import { eventSlice } from './event';
import { participantSlice } from './participant/participantSlice';

export const store = configureStore({
	reducer: {
		event: eventSlice.reducer,
		participant: participantSlice.reducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type EventState = ReturnType<typeof store.getState>
// export type ParticipantState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
