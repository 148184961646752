import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventState } from '~/store';
import { logout } from '~/store/event';
import { clearParticipantsLogout } from '~/store/participant/participantSlice';
import { useAppDispatch, useAppSelector } from '~/hooks/hooks';
import { getCachedEvents, removeCachedEvent } from '~/helpers/token-helpers';
import { Button, Drawer, IconButton } from 'rsuite';
import OffIcon from '@rsuite/icons/Off';
import ExitIcon from '@rsuite/icons/Exit';
import SettingIcon from '@rsuite/icons/Setting';
import logo from '../../tappin-logo.svg';

import styles from './Header.module.less';

export const Header = () => {

	const { name, logoURL, accessCode } = useAppSelector( (state: EventState) => state.event );
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [openSettings, setOpenSettings] = useState(false);

	const handleRedirectToStart = () => {
		dispatch( logout('') );
		dispatch( clearParticipantsLogout() );
		navigate('/');
	};

	const handleLogout = () => {
		if (accessCode) removeCachedEvent(accessCode);
		handleRedirectToStart();
	};

	return (
		<>
			<div className={styles.container}>
				<header className={styles.header}>
					<img src={logoURL ?? logo} className={styles.logo} alt="logo" />
					<IconButton
						icon={<SettingIcon />}
						appearance="link"
						color="cyan"
						size="lg"
						onClick={() => setOpenSettings(true)}
					/>
					<div className={styles.event}>
						{ name 
							??
							getCachedEvents().map(([accessKey, event]) => (
								(<span key={accessKey}>{event.eventName}</span>) ?? ''
							))
						}
					</div>
				</header>
			</div>
			<Drawer
				size="full"
				open={openSettings}
				onClose={() => setOpenSettings(false)}
				className={styles.settings}
			>
				<Drawer.Header>
					<Drawer.Title color="cyan">Settings</Drawer.Title>
				</Drawer.Header>
				<Drawer.Body>
					<h5>Select an event</h5>
					<ul className={styles.links}>
						{getCachedEvents().map(([accessKey, event]) => (
							<li key={accessKey}>
								<a className={styles.link} href={`/event/${accessKey}`}>
									{event.eventName}
								</a>
							</li>
						))}
					</ul>
					<div className={styles.actions}>
						<Button
							appearance="primary"
							block
							color="cyan"
							onClick={handleRedirectToStart}
						>
							<div className={styles.action}>
								<OffIcon /> Home
							</div>
						</Button>
						<Button appearance="default" block onClick={handleLogout}>
							<div className={styles.action}>
								<ExitIcon /> Logout
							</div>
						</Button>
					</div>
				</Drawer.Body>
			</Drawer>
		</>
	)
}
