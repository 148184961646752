import { createSlice } from '@reduxjs/toolkit';
import { ParticipantState } from '~/types/ParticipantState';

const initialState: ParticipantState = {
	isAdding: false,
	participants: null,
	lastLoaded: null,
	messageSaved: '',
	messageError: '',
	isScanningVouchers: false,
	isLoadedScannedIn: false,
}

export const participantSlice = createSlice({
	name: 'participant',
	initialState,
	reducers: {
		addingParticipant: ( state ) => {
			state.isAdding = true;
		},
		setNewParticipant: (state, action ) => {
			state.participants?.push( action.payload );
			state.isAdding = false;
			state.messageSaved = 'Participant admitted successfully';
		},
		setLoadedParticipant: ( state, action ) => {
			state.lastLoaded = action.payload;
		},
		clearParticipantsLogout: ( state ) => {
			state.isAdding = false;
			state.participants = [];
			state.lastLoaded = null;
			state.messageSaved = '';
			state.messageError = '';
		},
		setMessageError: (state, action) => {
			state.messageError = action.payload
		},
		startGettingParticipants: ( state ) => {
			state.isLoadedScannedIn = false;
		},
		setParticipants: ( state, action ) => {
			state.participants = action.payload;
			state.isLoadedScannedIn = true;
		},
		scanningVoucher: ( state ) => {
			state.isScanningVouchers = true;
			state.messageError = '';
		},
		consumeVoucher: ( state, action ) => {
			// state.isScanningVouchers = false;
			if (state.participants) {
				state.participants = state.participants?.map( p => {
					if ( p.id === action.payload.id ) {
						p.voucherBalance = action.payload.voucherBalance;
	
						return p;
					}
					return p;
				})
				state.messageSaved = 'Voucher consumed';
			}
		},
		clearLoadedParticipant: ( state ) => {
			state.messageError = '';
			state.messageSaved = '';
			state.lastLoaded = null;
			state.isScanningVouchers = false;
		},
	}
});

// Action creators are generated for each case reducer function
export const { addingParticipant, setNewParticipant, setLoadedParticipant, clearParticipantsLogout, setMessageError, startGettingParticipants, setParticipants, scanningVoucher, consumeVoucher, clearLoadedParticipant } = participantSlice.actions;