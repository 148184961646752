import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EventState } from '~/store';
import { checkingEvent, saveScanner, setLoadedScannedName } from '~/store/event';
import { useAppDispatch, useAppSelector } from '~/hooks/hooks';
import { Header } from '../Header/Header';
import { Button, ButtonToolbar, IconButton, Input } from 'rsuite';
import PeoplesIcon from '@rsuite/icons/Peoples';
import CouponIcon from '@rsuite/icons/Coupon';

import './style.css';
import styles from './ChoiceOption.module.less';
import { getScannerUserToken } from '~/helpers/token-helpers';

export const ChoiceOption = () => {
	const { status, errorMessage, isAdding } = useAppSelector( (state: EventState) => state.event );
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [userScanner, setUserScanner] = useState('');
	const [isNameSaved, setIsNameSaved] = useState(false);
	const [nameType, setNameType] = useState('save') 

	useEffect(() => {
		if ( status === 'not-loaded' ) {
			dispatch( checkingEvent( id ) );
		}
		if(id) {
			const userScannerToken = getScannerUserToken(id)
			if(userScannerToken) {
				setIsNameSaved(true);
				setNameType('change')
				setUserScanner(userScannerToken || '');
				dispatch( setLoadedScannedName( true ) );
			}
		}
	}, []);

	useEffect(() => {
		if (errorMessage && errorMessage.length > 0) {
			navigate('/');
		}
	}, [errorMessage]);
	
	const onLoadTickets = () => {
		navigate('tickets');
	}

	const onLoadVouchers = () => {
		navigate('vouchers');
	}

	function handleUserScannerChange(value: string) {
		setUserScanner(value);
	}

	function handleName(value: string, type: string) {

		if(type === 'save'){
			setNameType('change')
		}
		else {
			setNameType('save')
		}
		if(nameType === 'save' && value.length > 0) {
			setIsNameSaved(true)
			dispatch(saveScanner(id, userScanner))
		}
		else {
			setIsNameSaved(false)
		}
	}

	return (
		<>
			<Header />
			<div className={styles.container}>
				<div className={styles.content}>
					<h3 className={styles.info}>Enter device name</h3>
					{errorMessage && <p>Error</p>}
					<div className={styles['name-container']}>
						<Input
							className={styles['user-scanner-input']}
							onChange={handleUserScannerChange}
							value={userScanner}
							disabled={isNameSaved}
						/>
						<Button
						onClick={() => handleName(userScanner, nameType)} 
						disabled={userScanner.length < 1}
						>
							{isNameSaved ? 'Change name' : 'Save name'}
						</Button>
					</div>
					<div className={styles.info}>Select an option</div>
					<div className={styles['select-buttons']}>
						<ButtonToolbar>
							<IconButton size='lg' icon={<PeoplesIcon />} onClick={ onLoadTickets } disabled={!isAdding} block>Scan Participants</IconButton>
							<IconButton size='lg' icon={<CouponIcon />} onClick={ onLoadVouchers } disabled={!isAdding} block>Scan Vouchers</IconButton>
						</ButtonToolbar>
					</div>
				</div>
			</div>
		</>
	)
}
